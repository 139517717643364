import { Card, CardContent, Container, Link, Typography } from '@mui/material'
import { FlexCol } from '@xylabs/sdk-react'
import React from 'react'

import { CustomAppBar } from '../CustomAppBar'
import { CustomBasePage } from '../CustomBasePage'
import bgImg from '../img/contactBackground.png'

export const Contact: React.FC = () => {
  return (
    <CustomBasePage appBar={<CustomAppBar />} paddingY={8} bgImg={bgImg}>
      <Container>
        <FlexCol alignItems="stretch" paddingY={2}>
          <FlexCol padding={2} alignItems="stretch">
            <Card>
              <CardContent>
                <Typography component="h2" variant="h5">
                  Booking
                </Typography>
                <Typography component="p" variant="body2">
                  Agency: United Talent <br />
                  Please contact United Talent or send email to{' '}
                  <Link href="mailto:gsc@wmeagency.com" target="_blank">
                    scott.clayton@unitedtalent.com
                  </Link>
                  .
                </Typography>
              </CardContent>
            </Card>
          </FlexCol>
          <FlexCol padding={2} alignItems="stretch">
            <Card>
              <CardContent>
                <Typography component="h2" variant="h5">
                  Other Inquiries
                </Typography>
                <Typography component="p" variant="body2">
                  Management: Left Seat Mgmt (Jim Weatherson) <br />
                  Please send an email to{' '}
                  <Link href="mailto:jim@leftseatmgmt.com" target="_blank">
                    jim@leftseatmgmt.com
                  </Link>
                  .
                </Typography>
              </CardContent>
            </Card>
          </FlexCol>
        </FlexCol>
      </Container>
    </CustomBasePage>
  )
}
