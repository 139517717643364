import { Box, Button, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { BasePage, FlexCol, FlexRow } from '@xylabs/sdk-react'
import React, { useEffect, useState } from 'react'
import { MdPlayCircleFilled } from 'react-icons/md'

import { CustomAppBar } from '../CustomAppBar'

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

function useCurrentWidth() {
  // save current window width in the state object
  const [width, setWidth] = useState(getWidth())

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    const resizeListener = () => {
      setTimeout(() => setWidth(getWidth()), 150)
    }
    // set resize listener
    window.addEventListener('resize', resizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return width
}

const getHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridButton: {
      padding: 0,
    },
    play: {
      color: theme.palette.common.black,
      fontSize: '10vmin',
      opacity: 0.5,
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
    },
  }),
)

const tileData = [
  {
    cols: 1,
    img: '/img/home/losingsleep.jpg',
    link: 'https://elisetrouw.lnk.to/LosingSleep',
    mobileImg: '/img/home/tall-losingsleep.jpg',
    playButton: true,
    title: '',
  },
  {
    cols: 1,
    img: '/img/home/tour.jpg',
    link: '/tour',
    mobileImg: '/img/home/tall-tour.jpg',
    playButton: true,
    title: '',
  },
  {
    cols: 1,
    img: '/img/home/maninvisible.jpg',
    link: 'https://elisetrouw.lnk.to/ManInvisible',
    mobileImg: '/img/home/tall-maninvisible.jpg',
    playButton: true,
    title: '',
  },
  {
    cols: 1,
    img: '/img/home/seethrough.jpg',
    link: 'https://elisetrouw.lnk.to/SeeThrough',
    mobileImg: '/img/home/tall-seethrough.jpg',
    playButton: true,
    title: '',
  },
  {
    cols: 1,
    img: '/img/home/htgwyw.jpg',
    link: 'https://www.youtube.com/watch?v=zGiOkuoJGyk',
    mobileImg: '/img/home/tall-htgwyw.jpg',
    playButton: true,
    title: '',
  },
  {
    cols: 1,
    img: '/img/home/merch2.jpg',
    link: 'https://shop.elisetrouw.com',
    mobileImg: '/img/home/tall-merch2.jpg',
    playButton: false,
    title: '',
  },
  {
    cols: 1,
    img: '/img/home/sticks.jpg',
    link: 'https://youtu.be/UeshU2wRgy4',
    mobileImg: '/img/home/tall-sticks.jpg',
    title: '',
  },
  {
    cols: 1,
    img: '/img/home/getwhatyouwant.jpg',
    link: 'https://www.youtube.com/watch?v=_ybVhx7GrQg',
    mobileImg: '/img/home/tall-getwhatyouwant.jpg',
    title: '',
  },
  {
    cols: 1,
    img: '/img/home/makebelieve.jpg',
    link: 'https://www.youtube.com/watch?v=0iAlaUUCfGk',
    mobileImg: '/img/home/tall-makebelieve.jpg',
    title: '',
  },
  {
    cols: 1,
    img: '/img/home/spotify.jpg',
    link: 'https://open.spotify.com/artist/6jeroC7T0j4Dvz9y3gtofR',
    mobileImg: '/img/home/tall-spotify.jpg',
    title: '',
  },
  {
    cols: 1,
    img: '/img/home/everlove.jpg',
    link: 'https://www.youtube.com/watch?v=Gl9GtO_vQxw',
    mobileImg: '/img/home/tall-everlove.jpg',
    title: '',
  },
  {
    cols: 1,
    img: '/img/home/awake.jpg',
    link: 'https://www.youtube.com/watch?v=D-lpAGzshMo',
    mobileImg: '/img/home/tall-awake.jpg',
    title: '',
  },
]

export const Home: React.FC = () => {
  const classes = useStyles()

  const width = useCurrentWidth()
  let height = getHeight()
  const vertical = height > width

  if (vertical) {
    height = width * 1.78
  } else {
    height = width * 0.56
  }

  return (
    <BasePage appBar={<CustomAppBar />}>
      <FlexCol style={{ width: width }}>
        {tileData.map((tile) => (
          <FlexRow key={tile.img} height={height}>
            <Button className={classes.gridButton} href={tile.link} rel="noopener noreferrer" target="_blank">
              <img alt={tile.title} src={vertical ? tile.mobileImg : tile.img} style={{ width: width }} />
              <Box display="block" position="absolute">
                <MdPlayCircleFilled className={classes.play} display={tile.playButton ?? true ? 'block' : 'none'} />
              </Box>
            </Button>
          </FlexRow>
        ))}
      </FlexCol>
    </BasePage>
  )
}
